.content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: fit-content;
}
h1{
    margin: 0;
    font-family: "Playfair Display";
}
.bandcamp-embed{
    width: fit-content;
    height: 120px;
    border: 0;
    margin: 10px;
}

.lyrics{
    font-style:italic;
    font-weight: bold;
}