.project-list{
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-height: 300px;
    overflow-x: auto;
    justify-content: center;
    align-items: center;
    margin: 40px 0 40px 0;
    padding: 10px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    border-radius: 1px;
    border:dashed 1px var(--tertiary);
    box-sizing: border-box;
}

.project-list::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

@media only screen and (max-width: 768px) {
    .project-list{
        width: 80vw;
        font-size: 11px;
        height: fit-content;
    }
  }