.release-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: break-word; /* Adjusts text wrapping */
    margin: 9px;
    width: 300px;
}
.release-card > *{
    margin: 0;
}

.release-description{
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    
}
.release-credits{
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
}

.release-art{
    background-color: red;
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
}

.icons{
    padding: 0;
    width: 30%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}