.releases-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 200px 100px 200px;
    max-width: 100vw;
    height:fit-content;
    margin-bottom: 200px;
}

@media (max-width:769px) {
    .releases-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 50px;
        height: fit-content;
        width: 95vw;
    }
}