.content-window {
    /* outline: solid var(--secondary) 1px; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 5px;
    height: 800px;
    width: 1100px;
}

@media (max-width:769px) {
    .content-window {
        /* outline: solid var(--secondary) 1px; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 5px;
        height: 80vh;
        width: 95vw;
    }
}