.youtube-link{
    width:100%;
    height:80%;
}

.external-link{
    color: blue;
}

h1{
    margin: 0;
    font-family: "Playfair Display";
}
.markdown-body{
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width:70%;
    max-width: 650px;
}

.github-readme-md{
    width: 100%;
    display: flex;
    justify-content: center;
}

.flex{
    display: flex;
    flex-direction: column;
    height: 100%;
}