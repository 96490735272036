.about-main-content{
    margin: 50px;
    max-width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.about-main-conten p {
    height: fit-content;
    margin: 0;
}
.contact{
    padding: 0 20px 0 20px;
    width: fit-content;
    background-color: wheat;
}
.contact-text{
    color: blue;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.contact-text:hover{
    cursor:pointer
}

.not-clicked{
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}