.dir-list{
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-height: 100px;
    overflow-x: auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    border-radius: 1px;
    border:dashed 1px var(--tertiary);
    box-sizing: border-box;
}
.dir-list::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

@media only screen and (max-width: 768px) {
    .dir-list{
        width: 80vw;
        font-size: 11px;
    }
  }