/*global css*/

:root {
  --primary: #FFF6E9;
  --secondary: #BBE2EC;
  --tertiary: #0D9276;
  --special:#40A2E3;
  --body-text: #000000;
  --alt-text: #0D9276;
  --heading-text: #40A2E3;
  color:var(--body-text);
  background-color: var(--primary);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

html{
  overflow-x: hidden;
}

a{
  text-decoration: none;
  color: inherit;
}

li{
  list-style-type: none;
}

.page{
  width: 100vw;
  height:100vh;
  text-wrap: wrap;
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
}

.page::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

