.projects-container {
    /* outline: solid var(--secondary) 1px; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    /* margin: 50px; */
    max-width: 80vw;
    height:70vh;
}

@media (max-width:769px) {
    .projects-container {
        
        /* outline: solid var(--secondary) 1px; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 50px;
        height: 70vh;
        width: 95vw;
    }
}